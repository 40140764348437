// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-advanced-tools-detail-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/advanced-tools/detail.js" /* webpackChunkName: "component---src-pages-advanced-tools-detail-js" */),
  "component---src-pages-advanced-tools-edit-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/advanced-tools/edit.js" /* webpackChunkName: "component---src-pages-advanced-tools-edit-js" */),
  "component---src-pages-advanced-tools-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/advanced-tools/index.js" /* webpackChunkName: "component---src-pages-advanced-tools-index-js" */),
  "component---src-pages-advanced-tools-new-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/advanced-tools/new.js" /* webpackChunkName: "component---src-pages-advanced-tools-new-js" */),
  "component---src-pages-advanced-tools-stats-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/advanced-tools/stats.js" /* webpackChunkName: "component---src-pages-advanced-tools-stats-js" */),
  "component---src-pages-agenda-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/agenda/index.js" /* webpackChunkName: "component---src-pages-agenda-index-js" */),
  "component---src-pages-ai-link-one-click-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/ai-link/one-click/index.js" /* webpackChunkName: "component---src-pages-ai-link-one-click-index-js" */),
  "component---src-pages-assign-medical-case-collaboration-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/assign-medical-case-collaboration/index.js" /* webpackChunkName: "component---src-pages-assign-medical-case-collaboration-index-js" */),
  "component---src-pages-case-edit-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/case/edit.js" /* webpackChunkName: "component---src-pages-case-edit-js" */),
  "component---src-pages-case-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/case/index.js" /* webpackChunkName: "component---src-pages-case-index-js" */),
  "component---src-pages-cases-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/cases/index.js" /* webpackChunkName: "component---src-pages-cases-index-js" */),
  "component---src-pages-cases-new-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/cases/new.js" /* webpackChunkName: "component---src-pages-cases-new-js" */),
  "component---src-pages-dashboard-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/dashboard/index.js" /* webpackChunkName: "component---src-pages-dashboard-index-js" */),
  "component---src-pages-file-upload-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/file/upload.js" /* webpackChunkName: "component---src-pages-file-upload-js" */),
  "component---src-pages-files-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/files/index.js" /* webpackChunkName: "component---src-pages-files-index-js" */),
  "component---src-pages-groups-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/groups/index.js" /* webpackChunkName: "component---src-pages-groups-index-js" */),
  "component---src-pages-hospital-new-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/hospital/new.js" /* webpackChunkName: "component---src-pages-hospital-new-js" */),
  "component---src-pages-hospital-settings-edit-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/hospital/settings/edit.js" /* webpackChunkName: "component---src-pages-hospital-settings-edit-js" */),
  "component---src-pages-hospital-settings-group-edit-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/hospital/settings/group/edit.js" /* webpackChunkName: "component---src-pages-hospital-settings-group-edit-js" */),
  "component---src-pages-hospital-settings-group-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/hospital/settings/group/index.js" /* webpackChunkName: "component---src-pages-hospital-settings-group-index-js" */),
  "component---src-pages-hospital-settings-option-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/hospital/settings/option/index.js" /* webpackChunkName: "component---src-pages-hospital-settings-option-index-js" */),
  "component---src-pages-hospital-settings-patients-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/hospital/settings/patients/index.js" /* webpackChunkName: "component---src-pages-hospital-settings-patients-index-js" */),
  "component---src-pages-hospital-settings-storage-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/hospital/settings/storage.js" /* webpackChunkName: "component---src-pages-hospital-settings-storage-js" */),
  "component---src-pages-hospital-settings-team-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/hospital/settings/team.js" /* webpackChunkName: "component---src-pages-hospital-settings-team-js" */),
  "component---src-pages-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/landing/index.js" /* webpackChunkName: "component---src-pages-landing-index-js" */),
  "component---src-pages-notifications-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/notifications/index.js" /* webpackChunkName: "component---src-pages-notifications-index-js" */),
  "component---src-pages-patient-edit-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/patient/edit.js" /* webpackChunkName: "component---src-pages-patient-edit-js" */),
  "component---src-pages-patient-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/patient/index.js" /* webpackChunkName: "component---src-pages-patient-index-js" */),
  "component---src-pages-patients-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/patients/index.js" /* webpackChunkName: "component---src-pages-patients-index-js" */),
  "component---src-pages-patients-new-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/patients/new.js" /* webpackChunkName: "component---src-pages-patients-new-js" */),
  "component---src-pages-plans-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/plans/index.js" /* webpackChunkName: "component---src-pages-plans-index-js" */),
  "component---src-pages-plans-public-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/plans/public.js" /* webpackChunkName: "component---src-pages-plans-public-js" */),
  "component---src-pages-protocols-edit-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/protocols/edit.js" /* webpackChunkName: "component---src-pages-protocols-edit-js" */),
  "component---src-pages-protocols-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/protocols/index.js" /* webpackChunkName: "component---src-pages-protocols-index-js" */),
  "component---src-pages-protocols-new-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/protocols/new.js" /* webpackChunkName: "component---src-pages-protocols-new-js" */),
  "component---src-pages-register-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/register/index.js" /* webpackChunkName: "component---src-pages-register-index-js" */),
  "component---src-pages-search-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/search/index.js" /* webpackChunkName: "component---src-pages-search-index-js" */),
  "component---src-pages-studies-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/studies/index.js" /* webpackChunkName: "component---src-pages-studies-index-js" */),
  "component---src-pages-study-advanced-tools-detail-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/study/advanced-tools/detail.js" /* webpackChunkName: "component---src-pages-study-advanced-tools-detail-js" */),
  "component---src-pages-study-advanced-tools-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/study/advanced-tools/index.js" /* webpackChunkName: "component---src-pages-study-advanced-tools-index-js" */),
  "component---src-pages-study-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/study/index.js" /* webpackChunkName: "component---src-pages-study-index-js" */),
  "component---src-pages-study-new-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/study/new.js" /* webpackChunkName: "component---src-pages-study-new-js" */),
  "component---src-pages-study-upload-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/study/upload.js" /* webpackChunkName: "component---src-pages-study-upload-js" */),
  "component---src-pages-user-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/user/index.js" /* webpackChunkName: "component---src-pages-user-index-js" */),
  "component---src-pages-user-settings-edit-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/user/settings/edit.js" /* webpackChunkName: "component---src-pages-user-settings-edit-js" */),
  "component---src-pages-user-settings-plan-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/user/settings/plan.js" /* webpackChunkName: "component---src-pages-user-settings-plan-js" */),
  "component---src-pages-virtual-visit-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/virtual-visit/index.js" /* webpackChunkName: "component---src-pages-virtual-visit-index-js" */),
  "component---src-pages-virtual-visits-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/virtual-visits/index.js" /* webpackChunkName: "component---src-pages-virtual-visits-index-js" */),
  "component---src-pages-welcome-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/welcome/index.js" /* webpackChunkName: "component---src-pages-welcome-index-js" */),
  "component---src-pages-work-studies-index-js": () => import("/var/lib/jenkins/workspace/alma-cloud-frontend_master/src/pages/work-studies/index.js" /* webpackChunkName: "component---src-pages-work-studies-index-js" */)
}

