
const initialState = {
  visibleSidebar: false,
  fixSidebar: true,
  visibleWorkspaces: false,
  showModal: false,
  showViewer: false,
  newsAlreadyRead: false,
  viewerImages: [],
  user: null,
  licenseWarning: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'SET_VISIBILITY_FILTER':
      return { ...state, ...action.filter };
    case 'TOGGLE_MODAL':
      return { ...state, ...action.modalState };
    case 'NEWS_ALREADY_READ':
      return { ...state, ...action.newsAlreadyRead };
    case 'TOGGLE_VIEWER':
      return { ...state, ...action.showViewer };
    case 'SET_VIEWER_IMAGES':
      return { ...state, ...action.viewerImages };
    case 'SET_USER':
      return { ...state, ...action.user };
    case 'SET_LICENSE':
      return { ...state, ...action.licenseWarning };
    default:
      return state;
  }
};
